.stats {
	&-item {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		a {
			max-width: 100%;
			height: 100%;
		}
	}
	&-stat {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 0;
		margin-bottom: 0;
		color: $monza;
		font-size: 4rem;
		min-height: 80px;
		max-width: 100%;
	}
	hr {
		width: 60%;
		margin: $spacer*1.25 auto;
		border-color: palette(gray,300);
	}
	&-info {
		font-size: 1.25rem;
		flex: 1 1 auto;
		max-width: 100%;
	}
}