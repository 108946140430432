.hoverTiles {
	margin-bottom: 40px;
	overflow: hidden;
}

.hoverTiles-title {
	padding-bottom: $spacer;
}

.hoverTiles-row {
	position: relative;
}

.hoverTiles-column {
	min-height: 290px;
	position: static;
	display: flex;
	flex-direction: column;
	&:nth-child(1) {
		@media (min-width: 768px) {
			.hoverTiles-item {
				&.is-active {
					.hoverTiles-info {
						transition: transform 0.25s ease, opacity 0.25s ease;
					}
					.hoverTiles-copy {
						transition: transform 0.25s ease;
					}
				}
			}
		}
	}
}

.hoverTiles-item {
	position: relative;
	margin-bottom: $spacer;
	transition: none;
	@media (min-width: 768px) {
		&:hover,
		&.is-active {
			.hoverTiles-image {
				filter: grayscale(100%);
			}
			.hoverTiles-name {
				transform: translateY(-10px);
				opacity: 1;
			}
		}
		&.is-active {
			position: absolute;
			z-index: 2;
			left: 10px;
			.hoverTiles-info {
				transition: transform 0.25s 0.25s ease, opacity 0.25s 0.25s ease;
				transform: scale(1, 1);
				pointer-events: all;
				opacity: 1;
			}
			.hoverTiles-copy {
				transition: transform 0.25s 0.25s ease;
				transform: scale(1, 1);
			}
		}
	}
}

.hoverTiles-image {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.25s ease;
	background-position: 50% 50%;
	background-size: cover;
	&:after {
		position: relative;
		display: block;
		padding-top: 100%;
		content: '';
	}
	@media (min-width: 768px) {
		cursor: pointer;
	}
}

.hoverTiles-name {
	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0;
	overflow: hidden;
	width: 100%;
	padding: $spacer;
	transition: 0.25s ease;
	transform: translateY(35px);
	text-align: left;
	font-size: 1.875rem;
	opacity: 0;
	color: $white;
	text-shadow: 1px 1px black;
}

.hoverTiles-info {
	overflow: hidden;
	padding: 0;
	background-color: $wedgewood;
	@media (min-width: 768px) {
		position: absolute;
		top: 0;
		left: 100%;
		overflow: hidden;
		width: calc(300% + 60px);
		height: 100%;
		padding: 0;
		transform: scale(0, 1);
		transform-origin: left top;
		pointer-events: none;
		opacity: 0;
	}
}

.hoverTiles-close {
	position: absolute;
	z-index: 2;
	top: 0;
	right: 0;
	display: none;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	cursor: pointer;
	background-color: rgba(white,0.2);
	&:before,
	&:after {
		position: absolute;
		width: 2px;
		height: 20px;
		content: '';
		transform-origin: center center;
		background-color: $white;
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
	@media (min-width: 768px) {
		display: flex;
	}
}

.hoverTiles-copy {
	z-index: 1;
	overflow-y: auto;
	max-height: 100%;
	margin: 0;
	padding: 0 $spacer*1.5 $spacer*1.5;
	color: $white;
	-webkit-overflow-scrolling: touch;
	@media (min-width: 768px) {
		padding: $spacer*0.5 $spacer*1.5 $spacer;
		transform: scale(2, 1);
	}
}

.hoverTiles-employee,
.hoverTiles-role,
.hoverTiles-location {
	padding: 0 1.5rem;
	color: $white;
	> span {
		font-family: 'planer-demibold';
	}
}

.hoverTiles-employee {
	padding-top: $spacer*1.5;
	@media (min-width: 1024px) {
		display: none;
	}
}

.hoverTiles-role {
	@media (min-width: 1024px) {
		padding-top: $spacer*1.5;
	}
}

.hoverTiles-location {
	padding-bottom: $spacer;
}