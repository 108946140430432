.btn {
	padding: 6px 12px;
	line-height: 1.45;
	&--wedgewood {
		background-color: $wedgewood;
		color: $white;
		&:hover {
			background-color: $white;
			color: $wedgewood;
		}
	}
}
