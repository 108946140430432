.callout {
	margin-bottom: 40px;

	&--imageRight {
		.callout-inner {
			@media (min-width: 768px) {
				flex-direction: row-reverse;
			}
		}
		.callout-content+.callout-image {
			@media (min-width: 768px) {
				padding-right: $spacer*2;
				padding-left: 0;
			}
		}
	}

	&-inner {
		@include wrapper;
		display: flex;
		flex-direction: column;
		align-items: center;

		@media (min-width: 768px) {
			flex-direction: row;
		}
	}

	&-title {
		width: 100%;
		padding-bottom: $spacer;
	}

	&-copy {
		width: 100%;
	}

	&-cta {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem 2.5rem 0.9375rem;
		border: 1px solid #ffc62c;
		background-color: #ffc62c;
		color: #333;
		font-size: 1rem;
		line-height: 1;
		text-align: center;
		cursor: pointer;

		&:hover {
			border: 1px solid #5c5a5b;
			background-color: #5c5a5b;
			color: #fff;
		}
	}
}

.callout.callout--hero {
	.callout-content {
		flex: 0 0 40%;
	}

	.callout-image {
		flex: 0 0 60%;
	}

	.callout-title {
		font-size: 3rem;
	}
}

.callout-content,
.callout-image {
	flex: 0 0 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.callout-content {

	@media (min-width: 768px) {
		order: initial;

	}
}

.callout-image {
	margin-bottom: $spacer;
	align-items: center;

	@media (min-width: 768px) {
		order: initial;
		margin-bottom: 0;
	}
}

.callout-img {
	max-width: 100%;
	flex-shrink: 0;
}

.callout-img-no-stretch {
	width: auto;
}



.callout-content+.callout-image {
	@media (min-width: 768px) {
		padding-left: $spacer*2;
	}
}

.callout-image+.callout-content {
	@media (min-width: 768px) {
		padding-left: $spacer*2;
	}
}

.callout-title {
	font-size: 2.5rem;
	font-weight: 700;
	line-height: 1.25;
	letter-spacing: 0;
	font-family: "Planer";
}

h1.callout-title {
	letter-spacing: -1px;
	font-family: Planer;
	font-size: 4em;
	line-height: 88px;
	font-weight: 500;
}