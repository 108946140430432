.gridTiles {
	.hide {
		display: none;
	}
	.gridTiles-wrapper {
		position: relative;
		display: grid;
		display: -ms-grid;
		grid-template-columns: 1fr;
		-ms-grid-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr;
		-ms-grid-rows: 1fr 1fr 1fr 1fr;
		// grid-gap: 1rem;
		// -ms-grid-gap: 1rem;
		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			// grid-gap: 1rem;
		}
	}
	&-item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		// width: 100%;
		// height: 100%;
		margin-bottom: 1rem;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		overflow: hidden;
		&:nth-child(1) {
			-ms-grid-row: 1;
			-ms-grid-column: 1;
			-ms-grid-column-span: 1; /* remember the gutter is a column track */
			grid-column: 1 / 1;
			grid-row: 1;
			@media (min-width: 768px) {
				grid-column: 1 / 2;
				margin-right: 0.5rem;
			}
		}
		&:nth-child(2) {
			-ms-grid-row: 2;
			grid-row: 2;
			-ms-grid-column: 1;
			-ms-grid-column-span: 1; /* remember the gutter is a column track */
			grid-column: 1 / 1;
			@media (min-width: 768px) {
				-ms-grid-row: 1;
				grid-row: 1;
				-ms-grid-column: 2;
				grid-column: 2 / 2;
				margin-left: 0.5rem;
			}
		}
		&:nth-child(3) {
			-ms-grid-row: 3;
			-ms-grid-column: 1;
			-ms-grid-column-span: 1; /* remember the gutter is a column track */
			grid-column: 1 / 1;
			grid-row: 3;
			@media (min-width: 768px) {
				-ms-grid-row: 2;
				grid-row: 2;
				grid-column: 1 / 2;
				margin-right: 0.5rem;
			}
		}
		&:nth-child(4) {
			-ms-grid-row: 4;
			-ms-grid-column: 1;
			-ms-grid-column-span: 1; /* remember the gutter is a column track */
			grid-column: 1 / 1;
			grid-row: 4;
			@media (min-width: 768px) {
				-ms-grid-row: 2;
				grid-row: 2;
				-ms-grid-column: 2;
				grid-column: 2 / 2;
				margin-left: 0.5rem;
			}
		}
		&--fullWidth {
			margin-right: 0 !important;
		}
		&Hover {
			display: flex;
			flex-direction: column;
			justify-content: center;
			transform: translateY(100%);
			width: 100%;
			height: 100%;
			padding: $spacer*1.25;
			background-color: $tango;
			text-align: center;
			transition: 0.25s ease;
			z-index: 3;
			> p {
				margin-bottom: $spacer;
				padding: 0;
				color: $white;
				font-size: 1rem;
			}
			.gridTiles-itemButtons {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-around;
				.btn {
					// width: 150px;
					margin-bottom: $spacer/2;
					padding: $spacer/2 $spacer*1.5;
					border: 0;
					font-size: 1rem;
					line-height: 1.5;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
		&:hover {
			.gridTiles-itemHover {
				transform: translateY(0);
			}
		}
	}
	&-itemTitle {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding-bottom: 0;
		color: $white;
		z-index: 1;
	}
}
