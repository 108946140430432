.intro {
	margin-bottom: 40px;
	&-title {
		margin-bottom: $spacer;
		padding-bottom: 0;
	}
	&-content {
		line-height: 1.75;
	}
	ul{
		display: inline-block;
	}
}