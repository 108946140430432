.article {

	&-tags {
		margin-bottom: $spacer;
		padding: $spacer 0;
	}

	&-tagsItem {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: $spacer*0.375 $spacer $spacer*0.25;
		background-color: $monza;
		border: 2px solid $monza;
		border-radius: 99px;
		color: $white;
		font-size: 0.875rem;
		line-height: 1;
		transition: 0.25s;
		&:not(:last-child) {
			margin-right: $spacer*0.5;
		}
		&:hover {
			background-color: $white;
			color: $monza;
			transition: 0.25s;
		}
	}

	&-bio {
		font-size: 0.875rem;
		font-style: italic;
	}
}