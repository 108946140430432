// component
.breadcrumb {
    display: block;
    align-items: center;
    justify-content: flex-start;
    list-style-type: none;
    margin: 0;
    padding: 0 1.25rem;
    &:hover {
        .breadcrumb-item {
            &:nth-child(1),
            &:nth-child(2) {
                display: block !important;
                width: auto;
                // &:after {
                // 	content: '...';
                // 	@media (min-width: 768px) {
                // 		content: '\f105';
                // 	}
                // }
            }
            // &:nth-child(2) {
            // 	&:after {
            // 		@media (min-width: 520px) {
            // 			content: '\f105';
            // 		}
            // 	}
            // }
        }
    }
    &-item {
        display: inline-block;
        &:before {
            display: none !important;
        }
        &:after {
            content: '\f105';
            padding-left: $spacer/2;
            padding-right: $spacer/2;
            font-family: $awesome;
            font-size: 0.875rem;
            font-weight: $awesomeWeight;
            line-height: 1.5;
        }
        &:last-child {
            &:after {
                content: '';
            }
        }
    }
    &-link,
    &-current {
        font-size: 1rem;
        font-weight: 400;
        text-decoration: none;
    }
    &-link {
        position: relative;
        color: $wedgewood;
        transition: 0.25s;
        &:after {
            position: absolute;
            content: '';
            bottom: -0.0625rem;
            left: 0;
            width: 0;
            height: 0.0625rem;
            background-color: $monza;
            transition: 0.25s;
        }
        &:hover {
            color: $monza;
            &:after {
                width: 100%;
            }
        }
    }
    &-current {
        color: lighten($abbey, 30%);
        font-weight: 600;
    }
}