.resourceTiles {
	display: flex;
	flex-direction: column;
	@media (min-width: 768px) {
		flex-direction: row;
	}
	&-block {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		@media (min-width: 768px) {
			flex-direction: row;
			flex-wrap: wrap;
			&:nth-child(1) {
				flex: 1 0 auto;
				max-width: 65%;
				margin-right: $spacer;
			}
			&:nth-child(2) {
				flex: 0 1 auto;
				min-width: 35%;
			}
		}
	}

	&-item {
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		max-width: 100%;
		min-height: 250px;
		margin-bottom: $spacer;
		padding: $spacer;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		@media (min-width: 768px) {
			flex: 1 0 100%;
		}
		&:nth-child(2),
		&:nth-child(3) {
			@media (min-width: 768px) {
				flex: 1 1 0;
			}
		}
		&:nth-child(2) {
			@media (min-width: 768px) {
				margin-right: $spacer;
			}
		}
	}

	&-headline {
		margin-top: auto;
		padding: 10px;
		background-color: $white;
		transition: 0.25s;
		a {
			display: block;
			color: $fontColorBase;
			&:hover {
				color: $gray;
			}
		}
	}

	&-preTitle {
		width: 100%;
		font-size: 0.875rem;
		text-transform: uppercase;
	}

	&-title {
		max-width: 100%;
		font-size: 1.25rem;
		font-family: 'planer-demibold';
	}
}




