.articleHero {
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@media (min-width:1024px) {
		padding-top: 6.25rem;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($black,0.4);
		z-index: 0;
	}
	
	&-headline {
		margin-top: $spacer*2.5;
		margin-bottom: 0;
		color: $white;
		line-height: 1;
		text-align: center;
		z-index: 1;
		@media (min-width:768px) {
			margin-top: $spacer*4.5;
		}
		@media (min-width:1024px) {
			margin-top: 6.25rem;
		}
	}
	&-preHeadline {
		display: block;
		margin-bottom: $spacer;
		font-size: 1rem;
		text-transform: uppercase;
	}
}