.relatedArticle {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-bottom: $spacer*2;
	color: $fontColorBase;
	@media (min-width: 768px) {
		flex-direction: row;
		align-items: flex-start;
	}

	&-img {
		width: 100%;
		height: 200px;
		margin-bottom: $spacer;
		background-position: center;
		background-size: cover;
		overflow: hidden;
		@media (min-width: 768px) {
			flex: 0 1 40%;
			margin-bottom: 0;
			margin-right: $spacer;
		}
	}
	&-content {
		@media (min-width: 768px) {
			flex: 1 0 60%;
		}
	}

	&-topic {
		display: block;
		color: $fontColorBase;
		font-size: 0.875rem;
		text-transform: uppercase;
		&:hover {
			color: $gray;
		}
	}

	&-title {
		display: block;
		color: $fontColorBase;
		font-size: 1.75rem;
		&:hover {
			h3 {
				color: $gray;
			}
		}
	}
}
