.resourcesSearch {
	padding: $spacer*2 0;

	&-heading {
		margin-bottom: $spacer*2;
		div {
			font-size: 1rem;
			font-family: 'Planer';
		}
	}

	&-title {
		margin-top: 35px;
		font-size: 30px;
		font-family: 'planer-medium';
		line-height: 35px;
	}

	&-categories {
		position: sticky;
		top: 0;
	}
}

.resource-cat-head {
	padding-top: 25px;
}

.resources-pdf {
	padding-top: 0;
}