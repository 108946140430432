.divider {
	@include wrapper {
		padding: 30px 0 40px;
	}
	box-sizing: content-box;
	overflow: hidden;
	height: 40px;
}

.divider-inner {
	position: relative;
	width: 1px;
	height: 100%;
	margin-left: 21%;
	transform: skewX(-42deg);
	color: $gray;
	background: currentColor;
	&:before,
	&:after {
		position: absolute;
		top: 0;
		left: 100%;
		width: 100vw;
		height: 1px;
		content: '';
		transform: scaleY(0.5);
		background: currentColor;
	}
	&:after {
		top: auto;
		right: 100%;
		bottom: 0;
		left: auto;
	}
}
