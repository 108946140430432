.articleMeta {
	padding: $spacer*1.5 0;
	@media (min-width:768px) {
		border-bottom: 1px solid palette(gray,200);
	}

	&-author {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: $spacer;
		@media (min-width:768px) {
			justify-content: flex-start;
			margin-bottom: 0;
		}
	}

	&-authorImg {
		width: 75px;
		height: 75px;
		margin-right: $spacer;
		border-radius: 50%;
		overflow: hidden;
		img {

		}
	}

	&-authorInfo {
		font-size: 1rem;
		div {
			&:first-child {
				font-family: 'planer-demibold';
			}
		}
	}

	&-share {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: $spacer 0;
		border-top: 1px solid palette(gray,200);
		@media (min-width:768px) {
			justify-content: flex-end;
			border-top: 0;
		}
		span {
			margin-right: $spacer;
			padding-top: 0.25rem;
			font-size: 0.875rem;
			line-height: 1;
			text-transform: uppercase;
		}
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			transition: 0.25s;
			&:hover {
				svg {
					color: $monza;
					transition: 0.25s;
				}
			}
		}
		svg {
			margin: 0 $spacer*0.5;
			width: 20px;
			height: 20px;
			color: $gray;
		}
	}
}