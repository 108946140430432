.accordion {
    display: flex;
    flex-direction: column;
		margin-bottom: 40px;
		&-locations {
			display: flex;
			flex-wrap: wrap;
		}
		&-location {
			flex: 0 0 100%;
			margin: 0 0 0.5rem;
			font-family: 'planer-demibold';
			//font-size: 16px;
			@media (min-width:768px) {
				flex: 0 0 50%;
			}
		}
}

.accordion-item {
    display: flex;
    flex-direction: column;
}

.accordion-trigger {
    transition: all .2s ease-in-out;
    margin-bottom:1px;
    padding: $spacer/2;
    background-color: palette(gray,50);
		display:flex;
		align-items: center;
    justify-content:flex-start;
    cursor:pointer;
    &:before {
			content:'';
			width: 0;
			height: 0;
			margin: 0 $spacer/2;
			border-left: 8px solid $gray;
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			transition: 0.25s linear;
    }
    .is-open & {
        background-color: palette(gray,500);
        color:$white;
        &:before {
					content:'';
					transform: rotate(90deg);
					border-left: 8px solid $white;
					border-top: 6px solid transparent;
					border-bottom: 6px solid transparent;
        }
    }
}

.accordion-content {
    display: none;
    padding: $spacer*2 $spacer;
    >*:last-child {
        margin-bottom: 0;
    }
    //just to display the content when the script adds the .is-open class on load and user click
    .is-open & {
        display:block;
    }
    //a nifty hook to do animations or whatevs when content is triggered open by user
    .is-opening & {
        animation: 1s accordion;
		}
		img {
			position: relative;
			max-width: 100%;
		}
}
@keyframes accordion {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}