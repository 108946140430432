// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/*
 * black and white for mixing
 */
$white: #fff;
$black: #000;
/*
 * Standard error/warning/success
 */
$success: #4caf50;
$warning: #ffeb3B;
$danger: #f44336;
/*
* Define base brand colors and gray variables here and then add them to the map below.
* Color names can be generated entering hex values into http://chir.ag/projects/name-that-color/
* Colors below are examples so you can cut/paste/replace
*/
$abbey: #54575a;
$silver: #bcbcbc;
$monza: #c20430;
$sunglow: #ffc72a;
$wedgewood: #4d86a0;
$tango: #ee7624;

$gray: #989ba0;
/*
* Populate the map below with the brand colors you entered above gray in map for shades
* Helper classes are being generated by this sass map in styles/scss/base/_helpers.scss
* as well as populating in pug/styleguide.pug
* USAGE (scss):
	div {
		color: palette(gray,500);
		background-color: palette(gray,50);
		border: 1px solid palette(gray,900);
	}
*/
$palettes: (
	gray: (
		50 : scale-color($gray, $lightness: 87%),
		100 : scale-color($gray, $lightness: 69%),
		200 : scale-color($gray, $lightness: 50%),
		300 : scale-color($gray, $lightness: 30%),
		400 : scale-color($gray, $lightness: 15%),
		500 : $gray,
		600 : scale-color($gray, $lightness: -9%),
		700 : scale-color($gray, $lightness: -19%),
		800 : scale-color($gray, $lightness: -29%),
		900 : scale-color($gray, $lightness: -48%),
	),
	success: (
		50 : scale-color($success, $lightness: 87%),
		100 : scale-color($success, $lightness: 69%),
		200 : scale-color($success, $lightness: 50%),
		300 : scale-color($success, $lightness: 30%),
		400 : scale-color($success, $lightness: 15%),
		500 : $success,
		600 : scale-color($success, $lightness: -9%),
		700 : scale-color($success, $lightness: -19%),
		800 : scale-color($success, $lightness: -29%),
		900 : scale-color($success, $lightness: -48%),
	),
	warning: (
		50 : scale-color($warning, $lightness: 87%),
		100 : scale-color($warning, $lightness: 69%),
		200 : scale-color($warning, $lightness: 50%),
		300 : scale-color($warning, $lightness: 30%),
		400 : scale-color($warning, $lightness: 15%),
		500 : $warning,
		600 : scale-color($warning, $lightness: -9%),
		700 : scale-color($warning, $lightness: -19%),
		800 : scale-color($warning, $lightness: -29%),
		900 : scale-color($warning, $lightness: -48%),
	),
	danger: (
		50 : scale-color($danger, $lightness: 87%),
		100 : scale-color($danger, $lightness: 69%),
		200 : scale-color($danger, $lightness: 50%),
		300 : scale-color($danger, $lightness: 30%),
		400 : scale-color($danger, $lightness: 15%),
		500 : $danger,
		600 : scale-color($danger, $lightness: -9%),
		700 : scale-color($danger, $lightness: -19%),
		800 : scale-color($danger, $lightness: -29%),
		900 : scale-color($danger, $lightness: -48%),
	));

//typography stuff - if using Bootstrap typography just ignore
$marginBase:2rem;
$lineHeightBase:1.5;

$awesome: FontAwesome;
$awesomeWeight: 900;

$fontSizeBase:1rem;
$fontColorBase:map-get(map-get($palettes, gray), 900);
$fontWeightBase:300;
$fontFamilyBase: Arial, 'Helvetica Neue', Helvetica, sans-serif;

$h1Size:2.25rem;
$h2Size:2rem;
$h3Size:1.75rem;
$h4Size:1.5rem;
$h5Size:1.25rem;
$h6Size:1rem;
$headingsFontFamily: Arial, 'Helvetica Neue', Helvetica, sans-serif;
$headingsFontWeight:400;
$headingsLineHeight:1.25;


//min and max width is used for the fluid type mixin and also limiting container widths
$minWidth:320px;
$maxWidth:1400px;

//bootstrap overrides here if needed
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
) !default;
